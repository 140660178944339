/**
 * 校验相关
 */
export function checkMobile(mobile: string): boolean {
  return /^1\d{10}$/g.test(mobile)
}

// 校验固定电话
export function checkTel(mobile: string): boolean {
  const isPhone = /^([0-9]{3,4}-)?[0-9]{7,8}$/
  return isPhone.test(mobile)
}

export function mobileValidator(rule: any, value: string, callback: (res?: any) => void) {
  if (checkMobile(value)) {
    callback()
  } else {
    callback(new Error('请填写正确的手机号'))
  }
}
