import { get } from '@/utils/http/request'
import type { RequestOptions } from '@/utils/http/types'

// 导出文件的随机数
export function getExportRandom(ids?: string, options?: RequestOptions) {
  return get('common/export/random', null, options)
}

// 批量下载随机数
export function getDownloadRandom(params?: { ids: string }, options?: RequestOptions) {
  return get('common/random', params, options)
}
