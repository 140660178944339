import { get, post, RequestOptions } from '@/utils/http'
import type {
  LoginData,
  LoginResult,
  EnumItem,
  TeamItem
} from './model/userModel'
import type { Permission } from '@/api/model/systemModel'

// 登录
export function login(data: LoginData, options: RequestOptions = {}) {
  options.useQueryString = true
  return post<LoginResult>('auth/login', data, options)
}

// 可登录的团队列表
export function getTeams(mobile: string, options?: RequestOptions) {
  return get<TeamItem[]>('auth/getTeamList', { mobile }, options)
}

// 获取验证码
export function getVerificationCode(
  params: { mobile: string },
  options?: RequestOptions
) {
  return get('auth/code', params, options)
}

// 公共枚举列表总和
export function getUnionEnum(
  options: RequestOptions = {}
) {
  return get<EnumItem[]>('sys/enum/list', options)
}

// 获取权限列表
export function getAsyncRoutes(options?: RequestOptions) {
  return get<Permission[]>('auth/getPermission', null, options)
}
