import { get, post } from '@/utils/http'
import type { RequestOptions } from '@/utils/http/types'
import type { PagerParams, ListResult } from './types'
import type {
  SettlementDetailParams,
  SettlementData,
  SettlementResult,
  AddSettlementResult,
  SupplierSet
} from './model/settlementModel'

export function getSettlementList(params?: any, options?: RequestOptions) {
  return get<ListResult<SettlementResult>>('settlement/page', params, options)
}

export function getSettlementDetail(settlementId: number, options?: RequestOptions) {
  return get<SettlementResult>(
    'settlement/page/detail',
    { settlementId },
    options,
    { timeout: 120000 }
  )
}

// 人员结算单详情-新增
export function getSettlementSalaryDetail(
  params?: SettlementDetailParams & { settlementId: number } & PagerParams,
  options?: RequestOptions
) {
  return get<ListResult>('settlement/salary/detail', params, options)
}

export function addSettlement(data: SettlementData, options?: RequestOptions) {
  const _data = new FormData()
  for (const key in data) {
    _data.append(key, data[key as keyof SettlementData] as string)
  }
  return post<string | AddSettlementResult>(
    'settlement/add',
    _data,
    options, { timeout: 120000 }
  )
}

// 确认结算单
export function confirmSettlement(settlementId: number, options?: RequestOptions) {
  return get('settlement/submit', { settlementId }, options)
}

// 结算发起，结算周期，是否默认上个月
export function getPreviousMonthChecked(options?: RequestOptions) {
  return get<{ status: 0 | 1 }>('settlement/rememberTime/list', {}, options)
}
// 结算发起，结算周期，设置是否默认上个月
export function setPreviosMonthChecked(checked: boolean, options?: RequestOptions) {
  return get('settlement/rememberTime/add', { status: checked ? 1 : 0 }, options)
}

// 撤销结算单
export function cancelSettlement(settlementId: number, options?: RequestOptions) {
  return get('settlement/rollback', { settlementId }, options)
}

// 导出结算明细
// export async function exportSettlementDetail(settlementId: number, options?: RequestOptions) {
//   const res = await getExportRandom()
//   if (!res.success) {
//     return res
//   }
//   const random = res.result
//   return await get('settlement/salary/detail/export', { settlementId, random })
// }

// 付款通知书详情
export function getPayNoticeDetail(settlementId: number, options?: RequestOptions) {
  return get('settlement/pay/notice', { settlementId }, options)
}

// 根据客户企业获取关联的服务主体和产品及账套
export function getSuppliersByCustomer(cid: number, options?: RequestOptions) {
  return get<SupplierSet[]>('org/for/cid', { cid }, options)
}
