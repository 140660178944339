import type { RouteRecordRaw } from 'vue-router'
import { AppFrame as Layout, BodyLayout } from '@/layout'

const Product = () => import(/* webpackChunkName: "service" */ '@/views/service/Product.vue')

const Entry = () => import(/* webpackChunkName: "service" */ '@/views/service/entry/Entry.vue')
const EntryDetail = () => import(/* webpackChunkName: "service" */ '@/views/service/entry/EntryDetail.vue')
const EntryTable = () => import(/* webpackChunkName: "service" */ '@/views/service/entry/EntryTable.vue')
const EntryTableDetail = () => import(/* webpackChunkName: "service" */ '@/views/service/entry/EntryTableDetail.vue')

const Staff = () => import(/* webpackChunkName: "service" */ '@/views/service/staff/Staff.vue')
const StaffDetail = () => import(/* webpackChunkName: "service" */ '@/views/service/staff/StaffDetail.vue')

const route: RouteRecordRaw = {
  path: '/service',
  name: 'service',
  component: Layout,
  meta: { title: '服务中心', isMenu: true, icon: 'service' },
  redirect: { name: 'service_entry_index' },
  children: [
    {
      path: 'product',
      name: 'service_product',
      component: Product,
      meta: { title: '产品管理' }
    },
    {
      path: 'entry',
      name: 'service_entry',
      component: BodyLayout,
      meta: { title: '入职管理', isMenu: true },
      redirect: { name: 'service_entry_index' },
      children: [
        {
          path: 'index',
          name: 'service_entry_index',
          component: Entry,
          meta: { title: '入职管理', isPermissionExtend: true }
        },
        {
          path: 'detail',
          name: 'service_entry_detail',
          component: EntryDetail,
          meta: { title: '入职详情' }
        },
        {
          path: 'table',
          name: 'service_entry_table',
          component: EntryTable,
          meta: { title: '入职登记表' }
        },
        {
          path: 'table-detail',
          name: 'service_entry_tableDetail',
          component: EntryTableDetail,
          meta: { title: '入职登记表详情' }
        }
      ]
    },
    {
      path: 'staff',
      name: 'service_staff',
      component: BodyLayout,
      meta: { title: '人事管理', isMenu: true },
      redirect: { name: 'service_staff_index' },
      children: [
        {
          path: 'index',
          name: 'service_staff_index',
          component: Staff,
          meta: { title: '传统用工', isPermissionExtend: true }
        },
        {
          path: 'detail',
          name: 'service_staff_detail',
          component: StaffDetail,
          meta: { title: '人员详情' }
        }
      ]
    }
  ]
}

export default route
