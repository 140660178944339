import type { RouteRecordRaw } from 'vue-router'
import type { Permission } from '@/api/model/systemModel'
import router from '@/router'

// 所有动态路由
const modulesFiles = require.context('@/router/modules', true, /\.ts$/)
const moduleRoutes = modulesFiles.keys().map(
  modulePath => modulesFiles(modulePath).default
)

function mergeRoutes(
  asyncRoutes: Permission[],
  appRoutes: RouteRecordRaw[] = moduleRoutes,
  level = 0
) {
  for (let i = 0, l = appRoutes.length; i < l; i++) {
    const route = appRoutes[i]
    if (!route.meta) {
      route.meta = {}
    }
    const { meta } = route
    const asyncRoute = asyncRoutes.find(r => r.permissionCode === route.name)
    if (asyncRoute) {
      const { permissionUrl: url } = asyncRoute
      // 设置url
      if (url && url.indexOf('/') < 0) {
        route.path = url
      }
      meta.id = asyncRoute.id
      meta.parentId = asyncRoute.parentId
      meta.title = asyncRoute.permissionName
      meta.order = asyncRoute.sort
      meta.permissionType = asyncRoute.permissionType
      if (meta.isMenu !== false) {
        meta.isMenu = asyncRoute.permissionType === 1
      }
      if (asyncRoute.icon) {
        meta.icon = asyncRoute.icon
      }
      meta.level = level

      // 设置子路由
      if (route.children) {
        mergeRoutes(
          asyncRoutes,
          route.children,
          level + 1
        )
        meta.leaf = false
      } else {
        meta.leaf = true
      }
    } else {
      route.meta.noPermission = !(
        (level > 0 && route.meta?.isPermissionExtend) ||
        route.meta.authNotRequired
      )
    }
  }
  appRoutes.sort((a, b) => {
    const orderA = parseInt(a.meta?.order as string) || 0
    const orderB = parseInt(b.meta?.order as string) || 0
    return orderA - orderB
  })
  return appRoutes
}

export function generateRoutes(asyncRoutes: Permission[]) {
  const routes = mergeRoutes(asyncRoutes)
  routes.forEach(router.addRoute)
  return routes
}
