/**
 * Axios请求包装
 */
import axios from 'axios'
import type { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios'
import qs from 'qs'
import { ElLoading, ElMessage } from 'element-plus'
import router from '@/router'
import type { RequestOptions, ResponseData } from './types'
import { getAuthToken, setAuthToken, removeAuthToken } from '../auth'

export const baseUrl = '/api/business'

// 创建axios对象，设置一些基本配置信息
export const axiosInstance: AxiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 30000
})

// 请求拦截
axiosInstance.interceptors.request.use((config) => {
  // 添加token
  const token = getAuthToken()
  if (token) {
    if (!config.headers) {
      config.headers = {}
    }
    config.headers.authorization = token
  }
  // baseUrl转换
  if (config.url?.indexOf('/') === 0) {
    config.baseURL = ''
  }
  return config
})

// 响应拦截
axiosInstance.interceptors.response.use(
  (response) => {
    const { status } = response
    // 非JSON类型，重新包装
    if (Object.prototype.toString.call(response.data) !== '[object Object]') {
      response.data = {
        status: response.status,
        code: response.status,
        success: true,
        result: response.data
      }
    }
    response.data.status = status

    // 保存token
    if (response.headers.authorization) {
      setAuthToken(response.headers.authorization)
    }
    return response
  },
  (error): AxiosResponse<ResponseData> => {
    // 包装错误信息，将请求错误正常返回，后面就不用try/catch处理
    if (!error.response) {
      // 请求超时
      const isTimeout = error.message.indexOf('timeout of') === 0
      if (isTimeout) {
        error.message = '网络超时'
      }
      error.response = {
        // http状态码，这里相当于自定义的状态码。1表示超时，0表示其他错误
        status: isTimeout ? 1 : 0
      }
    }

    const { response, message } = error

    // 重新包装信息返回
    response.data = {
      status: response.status,
      code: response.status,
      success: false,
      message
    }
    return response
  }
)

// 发送请求
export async function request<T>(
  config: AxiosRequestConfig,
  options?: RequestOptions
): Promise<ResponseData<T>> {
  // 设置默认值
  options = Object.assign({ loading: false, handleError: true }, options)
  const { loading, useQueryString, handleError, timeout, permissionCode } = options

  if (timeout) {
    config.timeout = timeout
  }

  if (!config.headers) {
    config.headers = {}
  }
  if (permissionCode) {
    config.headers['xfd-permission-code'] = permissionCode
  }

  let loadingInstance
  if (loading) {
    loadingInstance = ElLoading.service({
      lock: true,
      text: '加载中...'
    })
  }
  // 使用query string发送请求
  if (
    useQueryString &&
    config.method &&
    (
      config.method.toLowerCase() === 'post' ||
      config.method.toLowerCase() === 'put'
    )
  ) {
    config.transformRequest = [qs.stringify]
  }

  const response = await axiosInstance.request<ResponseData<T>>(config)

  if (loading && loadingInstance) {
    loadingInstance.close()
  }

  // session过期
  if (response.status === 401) {
    removeAuthToken()
    // 跳转到登录页
    // self.location.href = '/login?redirect=' + encodeURIComponent(router.currentRoute.value.fullPath)
    const currentRoute: any = router.currentRoute.value
    self.location.href =
      '/login?redirect=' +
      encodeURIComponent(
        currentRoute.meta.redirect
          ? currentRoute.meta.redirect
          : currentRoute.fullPath
      )
  } else {
    // 处理错误
    const { success, message } = response.data
    if (handleError && !success) {
      ElMessage({
        message: message || '请求出错',
        type: 'error'
      })
    }
  }
  response.data.headers = response.headers
  return response.data
}

// get请求
export function get<T>(
  url: string,
  params?: any,
  options?: RequestOptions,
  config?: AxiosRequestConfig
): Promise<ResponseData<T>> {
  config = Object.assign({}, config, {
    url,
    method: 'get',
    params
  })
  return request<T>(config, options)
}

// post请求
export function post<T>(
  url: string,
  data: Record<string, any> | FormData,
  options?: RequestOptions,
  config?: AxiosRequestConfig
): Promise<ResponseData<T>> {
  config = Object.assign({}, config, {
    url,
    method: 'post',
    data
  })
  return request<T>(config, options)
}

// put请求
export function put<T>(
  url: string,
  data: any,
  options?: RequestOptions,
  config?: AxiosRequestConfig
): Promise<ResponseData<T>> {
  config = Object.assign({}, config, {
    url,
    method: 'put',
    data
  })
  return request<T>(config, options)
}
