import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import router from './router'
import {
  setupElement,
  setupGlobalComponents,
  setupGlobalProperties,
  setupGlobalDirectives
} from './plugins'
import './rem-flexible'
import './permission'
import './styles/style.scss'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

createApp(App)
  .use(pinia)
  .use(router)
  .use(setupElement)
  .use(setupGlobalComponents)
  .use(setupGlobalProperties)
  .use(setupGlobalDirectives)
  .mount('#app')
