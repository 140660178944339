import { ElMessage } from 'element-plus'
import { getDownloadRandom } from '@/api/common'
import { baseUrl, get } from './http/request'
import type { ResponseData } from './http/types'
import { useLoading } from './loading'
import { blobToText } from './file'

// 下载链接
export function download(href: string, filename = '') {
  const downloadElement = document.createElement('a')
  downloadElement.href = href
  downloadElement.download = filename
  document.body.appendChild(downloadElement)
  downloadElement.click() // 点击下载
  document.body.removeChild(downloadElement) // 下载完成移除元素
}

export interface DownloadOptions {
  randomName?: string
  random?: string | boolean | (() => Promise<ResponseData<string>>)
  randomParams?: any
  handleError?: boolean | ((msg: string, res) => void)
}

// 转化JSON
function formatContent(content: string) {
  try {
    return JSON.parse(content)
  } catch (err) {
    return content
  }
}

export async function tokenDownload(url: string, options: DownloadOptions = {}) {
  const frame = document.createElement('iframe')
  frame.style.cssText = 'position: absolute; top: 0; left: -10000px; width: 100px;height: 100px'

  // 处理错误
  function handleError(msg, res) {
    if (typeof options.handleError === 'function') {
      options.handleError(msg, res)
    } else if (options.handleError !== false) {
      ElMessage({ type: 'error', message: msg || '下载出错' })
    }
  }

  function handleFrameLoaded(this: HTMLIFrameElement) {
    if (!this.src) {
      return
    }
    const res = formatContent(
      this.contentDocument
        ? this.contentDocument.body.textContent || ''
        : ''
    )
    const msg = typeof res === 'object' ? res.message : ''
    handleError(msg, res)
  }

  // 当弹出下载弹框时，不会触发该事件，所以该事件可以当成错误处理
  frame.addEventListener('load', handleFrameLoaded)
  document.body.appendChild(frame)

  // random
  let random = ''
  const getRandom = (
    typeof options.random === 'function'
      ? options.random
      : (options.random !== false ? getDownloadRandom : null)
  )
  if (typeof options.random === 'string') {
    random = options.random
  } else if (getRandom) {
    const res = await getRandom(options.randomParams)
    if (res.success) {
      random = res.result as string || ''
    } else {
      handleError(res.message || '请求出错', res)
      return res
    }
  }

  // url处理
  if (!(url.indexOf('/') === 0 || url.indexOf('http') === 0)) {
    url = `${baseUrl}/${url}`
  }

  // 添加random
  if (random) {
    const randomKey = options.randomName || 'random'
    const item = `${randomKey}=${random}`
    url += (url.indexOf('?') > -1 ? '&' : '?') + item
  }
  frame.src = url

  // 5分钟后移除iframe
  setTimeout(() => {
    frame.removeEventListener('load', handleFrameLoaded)
    frame.remove()
  }, 5 * 60 * 1000)
}

// 异步加载
type GetFilename = (contentDisposition: string) => string
interface AsyncDownloadParams {
  loading?: boolean
}
export async function asyncDownload(
  url: string | Promise<ResponseData>,
  filename: string | GetFilename = '',
  params: AsyncDownloadParams = {}
) {
  const [showLoading, hideLoading] = useLoading()
  const loading = params.loading !== false
  if (loading) {
    showLoading()
  }
  const res = await (
    typeof url === 'string'
      ? get(url, null, {}, { responseType: 'blob' })
      : url
  )
  if (res.success && res.result && res.headers) {
    const blob = res.result as Blob
    // 返回JSON，说明报错了
    if (blob.type === 'application/json') {
      try {
        const text = await blobToText(blob)
        const res = JSON.parse(text)
        if (!res.success) {
          ElMessage.error(res.message || '请求出错~')
        }
        return res
      } catch (e) {}
    } else {
      const href = window.URL.createObjectURL(blob)
      const contentDisposition = res.headers['content-disposition'] || res.headers['Content-Disposition']
      if (typeof filename === 'function') {
        filename = filename(contentDisposition)
      } else if (!filename) {
        filename = decodeURI(
          contentDisposition
            ? contentDisposition.split(/filename=/i)[1].replace(/"/g, '')
            : ''
        )
      }
      download(href, filename)
      window.URL.revokeObjectURL(href)
    }
  }
  if (loading) {
    hideLoading()
  }
  return res
}
