<template>
  <div class="no-auth">没有权限</div>
</template>
<style scoped>
.no-auth {
  margin-top: 20%;
  font-size: 18px;
  color: #999;
  text-align: center;
}
</style>
