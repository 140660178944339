import { Directive } from 'vue'
import { useStore } from '@/store/user'
import type { Permission } from '@/api/model/systemModel'

function checkPermissionExists(permissionCode: string, permissions: Permission[]) {
  const l = permissions.length
  for (let i = 0; i < l; i++) {
    const permission = permissions[i]
    if (permission.permissionCode === permissionCode) {
      return true
    }
    if (permission.subPermission) {
      const subExsits = checkPermissionExists(permissionCode, permission.subPermission)
      if (subExsits) {
        return true
      }
    }
  }
  return false
}

const permissionDirective: Directive = {
  mounted: (el, binding) => {
    if (!checkPermissionExists(binding.value, useStore().routes || [])) {
      el.style.display = 'none'
    }
  }
}

export default permissionDirective
