import type { RouteRecordRaw } from 'vue-router'
import { AppFrame as Layout, BodyLayout } from '@/layout'

const Invoice = () => import(/* webpackChunkName: "financing" */ '@/views/financing/Invoice.vue')
const InvoiceDetail = () => import(/* webpackChunkName: "financing" */ '@/views/financing/InvoiceDetail.vue')
const RelatedInvoice = () => import(/* webpackChunkName: "financing" */ '@/views/financing/RelatedInvoice.vue')

const route: RouteRecordRaw = {
  name: 'financing',
  path: '/financing',
  component: Layout,
  meta: { title: '财务中心', isMenu: true },
  redirect: '/financing/invoice/unrelated',
  children: [
    {
      name: 'financing_invoice',
      path: 'invoice',
      component: BodyLayout,
      meta: { title: '发票管理', isMenu: true },
      redirect: '/financing/invoice/unrelated',
      children: [
        {
          name: 'financing_invoice_unrelated',
          path: 'unrelated',
          component: Invoice,
          meta: { title: '待开票', isPermissionExtend: true }
        },
        {
          name: 'financing_invoice_related',
          path: 'related',
          component: RelatedInvoice,
          meta: { title: '已开票', isPermissionExtend: true }
        },
        {
          name: 'financing_invoice_detail',
          path: 'detail',
          component: InvoiceDetail,
          meta: { title: '结算单明细' }
        }
      ]
    }
  ]
}

export default route
