import { defineStore } from 'pinia'
import type { LoginResult as User } from '@/api/model/userModel'
import type { Permission } from '@/api/model/systemModel'
import { getAsyncRoutes } from '@/api/user'
import { generateRoutes } from '@/utils/generate-routes'
import { LoadState } from './types'
import { useStore as useAppStore } from './app'

type AsyncRoutes = Permission[] | null

export interface UserState {
  state: LoadState
  routes: AsyncRoutes
  userInfo: User | null
}

interface UserActions {
  setRoutes: (routes: Permission[]) => void
  loadRoutes: () => Promise<LoadState>
  setUser: (user: User) => void
  removeUser: () => void
}

export const useStore = defineStore<string, UserState, any, UserActions>('user', {
  state: () => ({
    state: LoadState.INITIAL,
    routes: [],
    userInfo: null
  }),
  actions: {
    setRoutes(routes: AsyncRoutes) {
      this.routes = routes
    },
    async loadRoutes() {
      if (this.state !== LoadState.LOADING) {
        this.state = LoadState.LOADING
        const res = await getAsyncRoutes({ handleError: false })
        if (res.success) {
          const routes = res.result || []
          generateRoutes(routes)
          this.$patch({
            state: LoadState.SUCCESS,
            routes
          })
          useAppStore().initFixed()
        } else {
          this.state = LoadState.FAILURE
        }
      }
      return this.state
    },
    setUser(userInfo) {
      this.userInfo = userInfo
    },
    removeUser() {
      this.userInfo = null
    }
  },
  persist: {
    key: 'user',
    storage: window.sessionStorage,
    paths: ['userInfo']
  }
})
