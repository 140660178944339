<template>
  <div class="logo">
    <img src="@/assets/images/logo.png" alt="" class="logo__img">
    <span class="logo__title">客户系统</span>
  </div>
</template>
<style lang="scss" scoped>
@use '@/styles/variables.scss' as *;

.logo {
  height: $topbar_main_height;
  overflow: hidden;
  line-height: $topbar_main_height;
  text-align: center;

  &__img {
    width: 25px;
    height: 25px;
    vertical-align: middle;
  }

  &__title {
    display: inline-block;
    margin-left: 8px;
    font-size: 18px;
    vertical-align: middle;
  }
}
</style>
