/**
 * 权限相关
 */
// token
// ---------------------------------
const tokenKey = 'authorization'

export function getAuthToken() {
  return localStorage.getItem(tokenKey)
}

export function setAuthToken(token: string): void {
  localStorage.setItem(tokenKey, token)
}

export function removeAuthToken(): void {
  localStorage.removeItem(tokenKey)
}
