import type { RouteRecordRaw } from 'vue-router'
import { AppFrame as Layout, BodyLayout } from '@/layout'

import SettlementUpload from '@/views/settlement/SettlementUpload.vue'
import SettlementStart from '@/views/settlement/SettlementStart.vue'
import SettlementConfirm from '@/views/settlement/SettlementConfirm.vue'
import SettlementList from '@/views/settlement/SettlementList.vue'
import SettlementDetail from '@/views/settlement/SettlementDetail.vue'

const route: RouteRecordRaw = {
  name: 'settlement',
  path: '/settlement',
  component: Layout,
  meta: { title: '结算中心', isMenu: true },
  redirect: '/settlement/start/upload',
  children: [
    {
      name: 'settlement_start',
      path: 'start',
      component: BodyLayout,
      meta: { title: '结算发起', isMenu: true },
      redirect: '/settlement/start/upload',
      children: [
        {
          name: 'settlement_start_upload',
          path: 'upload',
          component: SettlementUpload,
          meta: { title: '表格上传', authNotRequired: true }
        },
        {
          name: 'settlement_start_form',
          path: 'form',
          component: SettlementStart,
          meta: { title: '代发薪结算', authNotRequired: true }
        },
        {
          name: 'settlement_start_confirm',
          path: 'confirm',
          component: SettlementConfirm,
          meta: { title: '结算确认', authNotRequired: true }
        }
      ]
    },
    {
      name: 'settlement_list',
      path: 'list',
      component: BodyLayout,
      meta: { title: '结算记录', isMenu: true },
      redirect: '/settlement/list/index',
      children: [
        {
          name: 'settlement_list_index',
          path: 'index',
          component: SettlementList,
          meta: { title: '结算记录', authNotRequired: true }
        },
        {
          name: 'settlement_list_detail',
          path: 'detail',
          component: SettlementDetail,
          meta: { title: '结算明细', authNotRequired: true }
        }
      ]
    }
  ]
}

export default route
