import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { AppFrame as Layout } from '@/layout'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import NotFound from '@/views/NotFound.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Layout,
    redirect: { name: 'home_index' },
    children: [
      {
        path: '',
        name: 'home_index',
        component: Home,
        meta: { isPermissionExtend: true }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { title: '登录' }
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: NotFound,
    meta: {
      title: '页面找不到',
      authNotRequired: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
