import { defineStore } from 'pinia'

export interface SettlementState {
  settlementFile: File | undefined
}

export interface SettlementActions {
  setSettlementFile: (file: File) => void,
  clearSettlementFile: () => void
}

export const useStore = defineStore<string, SettlementState, any, SettlementActions>('settlement', {
  state: () => ({
    settlementFile: undefined
  }),
  actions: {
    setSettlementFile(file: File) {
      this.settlementFile = file
    },
    clearSettlementFile() {
      this.settlementFile = undefined
    }
  }
})
