/**
 * 权限控制
 */
import router from '@/router'
import { getAuthToken } from '@/utils/auth'
import { useStore as useAppStore } from '@/store/app'
import { useStore as useUserStore } from '@/store/user'
import { useStore as useEnumStore } from '@/store/enum'
import { LoadState } from './store/types'

// 是否已经生成路由
router.beforeEach(async (to, from, next) => {
  // 有权限或者无需权限
  if (getAuthToken() || to.path === '/login') {
    const user = useUserStore()
    // 已经加载路由，或无需加载路由
    if (
      user.state === LoadState.SUCCESS ||
      user.state === LoadState.FAILURE ||
      to.path === '/login'
    ) {
      next()
    } else {
      // 加载公共枚举
      const enums = useEnumStore()
      if (enums.state === LoadState.INITIAL || enums.state === LoadState.FAILURE) {
        await enums.loadEnums()
      }
      // 加载路由
      await user.loadRoutes()
      // 加载完成
      const { path, query } = to
      // 跳转。重新开始路由鉴权
      next({ path, query, replace: true })
    }
  } else { // 未登录
    next({
      name: 'login',
      query: {
        redirect: encodeURIComponent(to.fullPath)
      }
    })
  }
})

// Tab记录
router.afterEach(to => useAppStore().push(to))
