/**
 * 公共
 */
import { RouteLocationNormalized, RouteRecordNormalized, RouteLocationRaw } from 'vue-router'
import { defineStore } from 'pinia'
import router from '@/router'

export interface TabItem {
  key: string
  href: string
  title: string
  isFixed?: boolean
  ignoreKeepAlive: boolean
}

export function getLocationKey(route: RouteLocationNormalized) {
  const overrodeParent = route.matched.slice().reverse().find(item => item.meta?.isOverrideTab)
  if (overrodeParent) {
    return overrodeParent.path
  }
  return route[route.meta?.isTabStrict === false ? 'path' : 'fullPath'] as string
}

export interface AppState {
  tabItems: TabItem[]
}

export type AppGetters = {
  cacheTabItems: (state: AppState) => string[]
}

export interface AppActions {
  initFixed: (routes?: RouteRecordNormalized[]) => void
  findIndex: (key: string) => number
  push: (route: RouteLocationNormalized) => void
  remove: (route: number) => void
  clear: () => void
  goSelf: (to: RouteLocationRaw) => void
}

const blackList = [
  '/',
  '/login'
]

export const useStore = defineStore<string, AppState, AppGetters, AppActions>('app', {
  state: () => ({
    tabItems: []
  }),
  getters: {
    cacheTabItems(state) {
      return state.tabItems
        .filter(item => !item.ignoreKeepAlive)
        .map(item => item.href)
    }
  },
  actions: {
    // 初始化固定标签
    initFixed(routes?: RouteRecordNormalized[]) {
      if (!routes) {
        routes = router.getRoutes()
      }
      routes.filter(route => route.meta?.isFixedTab)
        .reverse()
        .forEach(route => {
          const { path, meta } = route
          if (this.tabItems.findIndex(item => path === item.key) < 0) {
            this.tabItems.unshift({
              key: path,
              href: path,
              title: meta.title as string,
              isFixed: true,
              ignoreKeepAlive: !!meta.ignoreKeepAlive
            })
          }
        })
    },
    findIndex(key) {
      return this.tabItems.findIndex(item => key === item.key)
    },
    push(route: RouteLocationNormalized) {
      const key = getLocationKey(route)
      if (!blackList.find(path => path === key)) {
        const meta = route.meta || {}
        const tabItem: TabItem = {
          key,
          href: route.fullPath,
          title: meta.title as string,
          ignoreKeepAlive: !!meta.ignoreKeepAlive
        }
        const index = this.findIndex(key)
        if (index < 0) {
          this.tabItems.push(tabItem)
        } else {
          Object.assign(this.tabItems[index], tabItem)
        }
      }
    },
    remove(index: number) {
      // 移除的tab是当前页，则跳转到前一个tab
      const currentIndex = this.findIndex(getLocationKey(router.currentRoute.value))
      if (currentIndex === index && index > 0) {
        router.push(this.tabItems[index - 1].href)
      }
      if (index >= 0) {
        this.tabItems.splice(index, 1)
      }
    },
    clear() {
      this.tabItems = []
    },
    goSelf(to: RouteLocationRaw) {
      router.push(to)
    }
  },
  persist: {
    key: 'app',
    storage: window.sessionStorage
  }
})
