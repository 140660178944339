import type { App } from 'vue'

import SvgIcon from '@/components/SvgIcon.vue'
import ToolBar from '@/components/ToolBar.vue'
import AppPagination from '@/components/AppPagination.vue'
import AppPage from '@/components/AppPage.vue'
import ThirdNav from '@/components/ThirdNav.vue'
import PageHeader from '@/components/PageHeader.vue'
import LinkButton from '@/components/LinkButton.vue'
import UploadButton from '@/components/UploadButton.vue'
import FluidTable from '@/components/FluidTable'

export function setupGlobalComponents(app: App<Element>): void {
  app.component(SvgIcon.name, SvgIcon)
  app.component(ToolBar.name, ToolBar)
  app.component(AppPagination.name, AppPagination)
  app.component(AppPage.name, AppPage)
  app.component(ThirdNav.name, ThirdNav)
  app.component(PageHeader.name, PageHeader)
  app.component(LinkButton.name, LinkButton)
  app.component(UploadButton.name, UploadButton)
  app.component(FluidTable.name, FluidTable)
}

export {
  SvgIcon,
  ToolBar,
  AppPagination,
  AppPage,
  ThirdNav,
  PageHeader,
  LinkButton,
  UploadButton,
  FluidTable
}
