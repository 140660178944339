export function getField(data, key: string) {
  const keys = key.split('.')
  for (let i = 0, l = keys.length; i < l; i++) {
    const k = keys[i]
    if (data[k] != null) {
      data = data[k]
    } else {
      return
    }
  }
  return data
}

export function setField(data, key: string, value) {
  const keys = key.split('.')
  for (let i = 0, l = keys.length; i < l; i++) {
    const k = keys[i]
    if (typeof data === 'object') {
      if (i === l - 1) {
        data[k] = value
      } else if (Object.getOwnPropertyDescriptor(data, k)) {
        data = data[k]
      }
    }
  }
}
