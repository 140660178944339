import { RouteRecordRaw } from 'vue-router'
import { AppFrame as Layout, BodyLayout } from '@/layout'

const Role = () => import('@/views/system/role/Role.vue')
const RoleEdit = () => import('@/views/system/role/RoleEdit.vue')
const Member = () => import('@/views/system/member/Member.vue')

const route: RouteRecordRaw = {
  path: '/system',
  name: 'system',
  component: Layout,
  redirect: { name: 'system_roles_roles' },
  meta: { title: '系统管理', isMenu: true, icon: 'system' },
  children: [
    {
      path: 'roles',
      name: 'system_roles',
      component: BodyLayout,
      redirect: { name: 'system_roles_roles' },
      meta: { title: '角色管理', isMenu: true },
      children: [
        {
          path: '',
          name: 'system_roles_roles',
          component: Role,
          meta: { title: '角色管理', isPermissionExtend: true }
        },
        {
          path: 'add',
          name: 'system_roles_add',
          component: RoleEdit,
          meta: { title: '新增角色' }
        },
        {
          path: 'edit',
          name: 'system_roles_edit',
          component: RoleEdit,
          meta: { title: '编辑角色' }
        }
      ]
    },
    {
      path: 'members',
      name: 'system_members',
      component: BodyLayout,
      redirect: { name: 'system_members_members' },
      meta: { title: '成员管理', isMenu: true },
      children: [
        {
          path: '',
          name: 'system_members_members',
          component: Member,
          meta: { title: '成员管理', isPermissionExtend: true }
        }
      ]
    }
  ]
}

export default route
