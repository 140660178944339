import { defineStore } from 'pinia'
import { EnumItem } from '@/api/model/userModel'
import { getUnionEnum } from '@/api/user'
import { LoadState } from './types'

type EnumGroup = Record<string, EnumItem>
type EnumUnion = Record<string, EnumGroup>

export interface EnumState {
  enums: EnumUnion
  state: LoadState
}

export interface EnumActions {
  loadEnums: () => Promise<EnumUnion>
}

function filterEnum(enums?:EnumGroup):EnumGroup {
  if (enums) {
    const res = {} as EnumGroup
    for (const p in enums) {
      if (p !== 'null') {
        res[p] = enums[p]
      }
    }
    return res
  }
  return {} as EnumGroup
}

function formatEnum(enumList: EnumItem[]): EnumUnion {
  const enums: EnumUnion = {}
  enumList.forEach(item => {
    if (item.dataType in enums) {
      enums[item.dataType] = filterEnum(enums[item.dataType])
    } else {
      enums[item.dataType] = {}
    }
    enums[item.dataType][item.dataKey] = item
  })
  return enums
}

export const useStore = defineStore<string, EnumState, any, EnumActions>('enum', {
  state: () => ({
    enums: {},
    state: LoadState.INITIAL
  }),
  actions: {
    async loadEnums() {
      if (this.state === LoadState.LOADING) {
        return this.enums
      }
      this.state = LoadState.LOADING
      const res = await getUnionEnum()
      if (res.success) {
        let enums: EnumUnion = {}
        if (res.result) {
          enums = formatEnum(res.result)
        }
        this.$patch({
          enums,
          state: LoadState.SUCCESS
        })
      } else {
        this.state = LoadState.FAILURE
      }
      return this.enums
    }
  }
})
