// 发起结算-是否本月
export const provideTypeEnum: Record<number, string> = {
  0: '手动维护',
  1: '沿用上月数据'
}

// 发放状态
export const statusMap = {
  1: '已完成',
  '-1': '不可发放',
  0: '可发放'
}

// 发放状态2
export const statusMap2 = {
  1: '发放成功',
  '-1': '发放失败',
  0: '待发放'
}
