(() => {
  const maxWidth = 1200
  const minWidth = 1024
  const root = document.documentElement
  const setFontSize = () => {
    let width = root.clientWidth
    if (width > maxWidth) {
      width = maxWidth
    } else if (width < minWidth) {
      width = minWidth
    }
    root.style.fontSize = (width / 10).toFixed(2) + 'px'
  }
  let timer: any
  window.addEventListener('resize', () => {
    timer && clearTimeout(timer)
    timer = setTimeout(setFontSize, 100)
  })
  setFontSize()
})()
