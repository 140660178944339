import type { App } from 'vue'
import { dateFormat, toRegularDate, toChineseDate } from '@/utils/date'
import { fixDecimal, toCurrency } from '@/utils/number'

export function setupGlobalProperties(app: App<Element>) {
  app.config.globalProperties.dateFormat = dateFormat
  app.config.globalProperties.toRegularDate = toRegularDate
  app.config.globalProperties.toChineseDate = toChineseDate
  app.config.globalProperties.fixDecimal = fixDecimal
  app.config.globalProperties.toCurrency = toCurrency
}
