import { get } from '@/utils/http/request'
import type { RequestOptions } from '@/utils/http/types'
import type { ProductListResult, SuppliersResult, OrgItem } from './model/serviceModel'

// 产品列表
export function productList(
  params: { pageNo: number, pageSize?: number, keyword?: string },
  options?: RequestOptions
) {
  return get<ProductListResult>('product/page', params, options)
}

// 获取供应商
export function getSuppliers(options?: RequestOptions) {
  return get<SuppliersResult>('settlement/findOrgAndProByCid', null, options)
}

// 查询团队服务主体
export function getOrgs(options?: RequestOptions) {
  return get<OrgItem[]>('org/findByTeam', null, options)
}

// 根据productId查询客户
export function getCustomersByProductId(params: { productId?: number }, options?: RequestOptions) {
  return get('company/findCompanyResponsible', params, options)
}

// 查询团队以及机构下的产品
export function getProductsByOrg(params: { orgId: number }, options?: RequestOptions) {
  return get('product/findByTeamAndOrg', params, options)
}
