import { ElLoading } from 'element-plus'

type LoadingInstance = ReturnType<typeof ElLoading.service>
type LoadingParams = Parameters<typeof ElLoading.service>[0]

const defaultParams = {
  lock: true,
  text: '加载中...'
}

export function useLoading() {
  let loadingInstance: LoadingInstance | undefined
  const showLoading = (params: LoadingParams = defaultParams) => {
    loadingInstance = ElLoading.service(params)
  }
  const hideLoading = () => {
    if (loadingInstance) {
      loadingInstance.close()
      loadingInstance = undefined
    }
  }
  return [showLoading, hideLoading]
}
