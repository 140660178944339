export function toCurrency(num: number | string, fixed = 2) {
  if (typeof num === 'string') {
    num = Number(num)
    if (isNaN(num)) {
      num = 0
    }
  }
  if (typeof num !== 'number') {
    num = 0
  }
  let isNegative = false
  if (num < 0) {
    isNegative = true
    num = Math.abs(num)
  }

  let str: string
  if (typeof fixed === 'number') {
    str = num.toFixed(fixed)
  } else {
    str = num.toString()
  }

  const parts = str.split('.')
  const intPart = parts[0]
  const intArr: string[] = []
  const start = intPart.length % 3 === 0 ? 3 : intPart.length % 3
  for (let i = start, j = 0; i <= intPart.length; j = i, i += 3) {
    intArr.push(intPart.slice(j, i))
  }
  parts[0] = intArr.join(',')

  if (isNegative) {
    parts[0] = '-' + parts[0]
  }

  return parts.join('.')
}

/**
 * 金额转大写
 */
// 汉字的数字
const cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
// 基本单位
const cnIntRadice = ['', '拾', '佰', '仟']
// 对应整数部分扩展单位
const cnIntUnits = ['', '万', '亿', '兆']
// 对应小数部分单位
const cnDecUnits = ['角', '分', '毫', '厘']
// 整数金额时后面跟的字符
const cnInteger = '整'
// 整型完以后的单位
const cnIntLast = '元'
// 最大处理的数字
const maxNum = 999999999999999.9999 // eslint-disable-line
export function toChineseCurrency(moneyStr: string | number): string {
  // 金额整数部分
  let integerNum: string
  // 金额小数部分
  let decimalNum: string
  // 输出的中文金额字符串
  let chineseStr = ''
  // 分离金额后用的数组，预定义
  let parts
  // 传入的参数为空情况
  if (moneyStr === '') {
    return ''
  }
  const money = parseFloat(String(moneyStr))
  if (money >= maxNum) {
    return ''
  }
  // 传入的参数为0情况
  if (money === 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger
    return chineseStr
  }
  // 转为字符串
  moneyStr = money.toString()
  // indexOf 检测某字符在字符串中首次出现的位置 返回索引值（从0 开始） -1 代表无
  if (moneyStr.indexOf('.') === -1) {
    integerNum = String(money)
    decimalNum = ''
  } else {
    parts = moneyStr.split('.')
    integerNum = parts[0]
    decimalNum = parts[1].substring(0, 4)
  }
  // 转换整数部分
  if (parseInt(integerNum, 10) > 0) {
    let zeroCount = 0
    const intLen = integerNum.length
    for (let i = 0; i < intLen; i++) {
      const n = integerNum.substring(i, i + 1)
      const p = intLen - i - 1
      const q = p / 4
      const m = p % 4
      if (n === '0') {
        zeroCount++
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0]
        }
        zeroCount = 0
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
      }
      if (m === 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q]
      }
    }
    // 最后+ 元
    chineseStr += cnIntLast
  }
  // 转换小数部分
  if (decimalNum !== '') {
    const decLen = decimalNum.length
    for (let i = 0; i < decLen; i++) {
      const n = decimalNum.substring(i, i + 1)
      if (n !== '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i]
      }
    }
  }
  if (chineseStr === '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger
  } else if (decimalNum === '') {
    chineseStr += cnInteger
  }

  return chineseStr
}
export function toFixedTwo(num) {
  return isNaN(num) ? (0).toFixed(2) : (+num).toFixed(2)
}

// 修复小数精度问题（部分修复）
export function fixDecimal(num: number | string, fixed = 2) {
  if (typeof num === 'string') {
    num = Number(num)
  }
  return Number(num.toFixed(fixed))
}

// 求和
export function getSum(...amounts: Array<number | string>) {
  return amounts
    .filter(num => !!num)
    .map(num => Number(num) || 0)
    .reduce((prev, cur) => prev + (cur || 0), 0)
}
